import { navigate } from "gatsby";
import { getCurrentLanguage } from "../i18n";
import { t } from "i18next";
import React, { FC, useEffect } from "react";
import SEO from "../components/SEO";
import "./index.scss";

interface IFrontPageProps {
  errors: Array<{ message: string }>;
}

const IndexPage: FC<IFrontPageProps> = (_) => {
  const metaDescription = t("siteDescription") || "";
  const siteTitle = t("siteTitle") || "";
  const currentLanguage = getCurrentLanguage();

  useEffect(() => {
    navigate(`/${currentLanguage}`);
  }, []);
  return <SEO slug="/" title={siteTitle} description={metaDescription} />;
};
export default IndexPage;
